#resumeContainer {
    padding-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90vh;
}

#resume {
    max-width: 100%;
    max-height: 100%;
}